<template>
  <b-modal
    ref="modalUpsertTemplate"
    :visible="editCourseSectionActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`Edit Course Section`"
    ok-title="Save Changes"
    centered
    body-class="pt-0"
    @ok="saveChanges"
  >
    <b-tabs v-model="tabCurrentIndex" content-class="mt-3" justified>
      <b-tab title="Roster" active>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Section Alias" label-for="course-section-nickname">
              <b-form-input id="course-section-nickname" placeholder="" v-model="courseSectionEditData.standard.nickname" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="6">
            <b-form-group label="Zoom Meeting ID" label-for="course-section-zoom-meeting-id">
              <b-form-input id="course-section-zoom-meeting-id" placeholder="" v-model="courseSectionEditData.standard.zoomMeetingId" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="6">
            <b-form-group label="Discord Server ID" label-for="course-section-discord-server-id">
              <b-form-input id="course-section-discord-server-id" placeholder="" v-model="courseSectionEditData.standard.discordServerId" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Discord Server Invite URL" label-for="course-section-discord-server-url">
              <b-form-input
                id="course-section-discord-server-url"
                placeholder=""
                v-model="courseSectionEditData.standard.discordInviteUrl"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="6">
            <b-form-group label="Visibility" label-for="course-section-visibility">
              <v-select
                v-model="courseSectionEditData.standard.visibility"
                :options="visibilityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                required
                transition="smooth"
                input-id="course-section-visibility"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="6">
            <b-form-group label="MyIP Integration/Automation" label-for="course-section-myip-integration">
              <b-form-checkbox
                id="course-section-myip-integration"
                :checked="courseSectionEditData.standard.metadata && courseSectionEditData.standard.metadata.myIp.enabled"
                @change="toggleMyIPEnabled"
                switch
                class="custom-control-primary"
                style="margin-top: 0.8rem"
              >
                {{
                  courseSectionEditData.standard.metadata && courseSectionEditData.standard.metadata.myIp.enabled ? 'Enabled' : 'Disabled'
                }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12" md="4" lg="4">
            <b-form-group label="Status" label-for="course-section-status">
              <v-select
                v-model="courseSectionEditData.status"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                required
                transition="smooth"
                input-id="course-section-status"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-tab>
      <b-tab v-if="$can('roster.section.compliance.update')" title="Compliance">
        <b-form>
          <b-form-group label="Scoring & Reporting (overrides)" label-for="compliance-enabled">
            <b-form-checkbox
              id="compliance-enabled"
              :checked="courseSectionEditData.compliance.enabled"
              @change="(val) => (courseSectionEditData.compliance.enabled = val)"
              switch
              class="custom-control-primary"
            >
              {{ courseSectionEditData.compliance.enabled ? 'Enabled' : 'Disabled' }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="!courseSectionEditData.compliance.enabled"
            label-cols-lg="3"
            label="Score Weights (inherited)"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-group label="Attendance:" label-for="compliance-weight-attendance" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseCompliance.weights.attendance"
                id="compliance-weight-attendance"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Exam Edge" label-for="compliance-weight-examedge" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseCompliance.weights.examedge"
                id="compliance-weight-examedge"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Simulations" label-for="compliance-weight-simulations" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseCompliance.weights.simulations"
                id="compliance-weight-simulations"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Labs" label-for="compliance-weight-labs" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseCompliance.weights.labs"
                id="compliance-weight-labs"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Labs (external)" label-for="compliance-weight-labs-external" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseCompliance.weights.labs_external"
                id="compliance-weight-labs-external"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Other" label-for="compliance-weight-other" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseCompliance.weights.other"
                id="compliance-weight-other"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>
          </b-form-group>

          <b-form-group
            v-if="courseSectionEditData.compliance.enabled"
            label-cols-lg="3"
            label="Score Weights (overrides)"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-group label="Attendance:" label-for="compliance-weight-attendance" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseSectionEditData.compliance.weights.attendance"
                id="compliance-weight-attendance"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Exam Edge" label-for="compliance-weight-examedge" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseSectionEditData.compliance.weights.examedge"
                id="compliance-weight-examedge"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Simulations" label-for="compliance-weight-simulations" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseSectionEditData.compliance.weights.simulations"
                id="compliance-weight-simulations"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Labs" label-for="compliance-weight-labs" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseSectionEditData.compliance.weights.labs"
                id="compliance-weight-labs"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Labs (external)" label-for="compliance-weight-labs-external" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseSectionEditData.compliance.weights.labs_external"
                id="compliance-weight-labs-external"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Other" label-for="compliance-weight-other" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                v-model="courseSectionEditData.compliance.weights.other"
                id="compliance-weight-other"
                type="number"
                min="0"
                max="100"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </b-form>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BModal,
  BRow,
  BTab,
  BTabs,
  VBModal,
} from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useCourseSections from '../useCourseSections';
import vSelect from 'vue-select';

export default {
  name: 'CourseSectionEditModal',
  components: {
    BTab,
    BTabs,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BModal,
    quillEditor,
    vSelect,
  },
  directives: { 'b-modal': VBModal },
  props: {
    editCourseSectionActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    courseSectionData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      tabCurrentIndex: 0,
      courseSectionEditData: { standard: undefined, compliance: undefined },
      courseCompliance: {},
    };
  },
  watch: {
    '$props.editCourseSectionActive'(val) {
      this.initModal();
    },
    '$props.courseSectionData'(val) {
      this.initModal();
    },
  },
  mounted() {
    this.initModal();
  },
  methods: {
    initModal() {
      if (this.$props.courseSectionData) {
        console.log(this.$props.courseSectionData);

        const { nickname, visibility, id, zoomMeetingId, discordServerId, discordInviteUrl, metadata, compliance } =
          this.$props.courseSectionData;
        this.courseSectionEditData.standard = {
          nickname,
          visibility,
          id,
          zoomMeetingId,
          discordServerId,
          discordInviteUrl,
          metadata,
        };
        this.courseSectionEditData.compliance = compliance;
        this.courseCompliance = this.$props.courseSectionData.course.compliance;
      }
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:edit-course-section-active', false);
    },
    toggleMyIPEnabled() {
      if (!this.courseSectionEditData.standard.metadata.myIp) {
        this.courseSectionEditData.standard.metadata.myIp = { enabled: true };
      } else {
        this.courseSectionEditData.standard.metadata.myIp.enabled = !this.courseSectionEditData.standard.metadata.myIp.enabled;
      }
    },
    saveChanges(event) {
      this.busy = true;

      event.preventDefault();

      if (this.tabCurrentIndex === 0) {
        const pl = { ...this.courseSectionEditData.standard };

        console.log(pl);

        store
          .dispatch('rosterStoreModule/updateCourseSection', { courseSectionId: this.courseSectionData.id, courseSectionData: pl })
          .then((response) => {
            // this.$emit('update:course-data', response.data);

            this.busy = false;
            this.$emit('refetch-data');
            this.closeModal();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Course Cohort Edited!',
                icon: 'EditIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.busy = false;
            this.$emit('refetch-data');
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to edit course cohort',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.response ? error.response.data.message : error.message,
              },
            });
          });
      } else if (this.tabCurrentIndex === 1) {
        const pl = { enabled: this.courseSectionEditData.compliance.enabled, weights: this.courseSectionEditData.compliance.weights };
        Object.keys(pl.weights).forEach((key) => (pl.weights[key] = parseFloat(pl.weights[key])));

        store
          .dispatch('rosterStoreModule/updateCourseSectionCompliance', { courseSectionId: this.courseSectionData.id, complianceData: pl })
          .then((response) => {
            // this.$emit('update:course-data', response.data);

            this.busy = false;
            this.$emit('refetch-data');
            this.closeModal();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Compliance Edited!',
                icon: 'EditIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.busy = false;
            this.$emit('refetch-data');
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to edit course',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.response ? error.response.data.message : error.message,
              },
            });
          });
      }
    },
  },
  setup(props) {
    const courseSectionEditData = ref({ standard: {}, compliance: {} });
    const busy = ref(false);

    const { statusOptions, visibilityOptions } = useCourseSections();

    return { statusOptions, visibilityOptions, courseSectionEditData, busy };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
