<template>
  <div class="pr-50">
    <AttendanceModal
      v-if="pathwayCohortData && isAttendanceOpen"
      :session-data.sync="pathwayCohortData"
      :is-table-open.sync="isAttendanceOpen"
    />

    <div class="grid">
      <h3 class="ml-25 mb-75">Actions</h3>
      <div class="col mb-2">
        <div class="row row-cols-2 mb-50">
          <div class="pr-25">
            <b-button variant="info" @click="publishDraft" class="w-100" v-if="pathwayCohortData.status === 'draft'" :disabled="isBtnBusy">
              <feather-icon icon="GlobeIcon" class="mr-25" />
              <span>Publish Draft</span>
            </b-button>
            <b-button
              variant="primary"
              @click="openTakeAttendance"
              class="w-100"
              :disabled="isBtnBusy"
              v-if="pathwayCohortData.status !== 'draft' && $can('roster.attendance.update')"
            >
              <feather-icon icon="CalendarIcon" class="mr-25" />
              <span>Take Attendance</span>
            </b-button>
          </div>
          <div class="pl-25">
            <b-dropdown
              class="mb-1"
              :disabled="isBtnBusy"
              text="Download Attendance"
              v-if="pathwayCohortData.status !== 'draft' && $can('roster.attendance.update') && pathwayCohortData.id"
              variant="primary"
              block
            >
              <b-dropdown-item v-if="$can('roster.attendance.export')" @click="downloadAttendanceSheet('standard')">
                All Student Standard
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('roster.attendance.export')" @click="downloadAttendanceSheet('extended')">
                All Student Extended
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <h3 class="ml-25 mb-75">Export</h3>

      <div class="col">
        <div class="row row-cols-2">
          <div v-if="$can('roster.cohort.export')" class="pr-25">
            <b-button variant="primary" @click="downloadRosterSheet" class="w-100" :disabled="isBtnBusy">
              <feather-icon icon="DownloadIcon" class="mr-25" />
              <span>Download Cohort Roster</span>
            </b-button>
          </div>

          <!-- idek why this is here?? -->
          <div v-if="false" class="pl-25">
            <b-button variant="primary" @click="downloadSectionRosterSheet" class="w-100 mb-1" :disabled="isBtnBusy">
              <feather-icon icon="DownloadIcon" class="mr-25" />
              <span>Download Section Roster</span>
            </b-button>
          </div>

          <div v-if="$can('integrations.monday.export.session')" class="pr-25">
            <b-button
              variant="primary"
              @click="exportPlacementToMonday(false)"
              @click.shift="exportPlacementToMonday(true)"
              class="w-100 mb-1"
              :disabled="isBtnBusy"
            >
              <font-awesome-icon icon="fak fa-monday" style="width: 12px; height: 12px; margin-bottom: 1px" />
              <span> Export to Monday</span>
            </b-button>
          </div>

          <div v-if="$can('accounting.receivable.cohort.create')" class="pr-25">
            <b-button
              class="mb-1"
              v-if="!pathwayCohortData.metadata.receivableCohort"
              :disabled="isBtnBusy"
              variant="warning"
              block
              @click="exportPlacementToReceivable(false)"
            >
              Create AR Cohort
            </b-button>
            <b-button
              class="mb-1"
              :disabled="isBtnBusy"
              v-else-if="pathwayCohortData.metadata.receivableCohort"
              variant="primary"
              block
              :to="{ name: `apps-accounting-receivable-cohort-view`, params: { id: pathwayCohortData.metadata.receivableCohort } }"
            >
              View AR Cohort
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { avatarText, title } from '@core/utils/filter';
import usePathwayCohorts from '../usePathwayCohorts';
import useAppConfig from '@core/app-config/useAppConfig';
import fileDownload from 'js-file-download';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';
import AttendanceModal from './components/AttendanceModal.vue';
import moment from 'moment';
import store from '@/store';

const { skin } = useAppConfig();

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    AttendanceModal,
  },
  props: {
    pathwayCohortData: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    const title = this.pathwayCohortData.name ? `${this.pathwayCohortData.name}` : 'View Pathway Cohort';
    return {
      title,
    };
  },
  data: () => {
    return {
      isBtnBusy: false,

      isAttendanceOpen: false,
      attendanceAttendedOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      attendanceTypeOptions: [
        { label: 'Present', value: 'present' },
        { label: 'Absent - Excused', value: 'absent_excused' },
        { label: 'Absent - Unexcused', value: 'absent_unexcused' },
        { label: 'Late', value: 'late' },
        { label: 'Special Case', value: 'special_case' },
        { label: 'Reassigned', value: 'reassigned' },
      ],

      attend: { type: 'present', attended: false, notes: '' },
    };
  },
  methods: {
    publishDraft() {
      this.isBtnBusy = true;

      const pl = this.pathwayCohortData;
      store
        .dispatch('rosterStoreModule/updatePathwayCohort', {
          cohortId: pl.id,
          pathwayCohortData: { status: 'created' },
        })
        .then(() => {
          this.isBtnBusy = false;

          this.$emit('refetch-data');

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pathway Cohort Published!',
              icon: 'EditIcon',
              variant: 'success',
            },
          }).catch((error) => {
            this.isBtnBusy = false;
          });
        });
    },
    openTakeAttendance() {
      this.isAttendanceOpen = true;
      // this.$bvModal.show('attendance-modal')
    },
    async downloadRosterSheet() {
      this.isBtnBusy = true;

      this.$store
        .dispatch('rosterStoreModule/fetchPathwayCohortRoster', {
          id: this.pathwayCohortData.id,
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          const name = `${this.resolvePathwayCohortName(this.pathwayCohortData)} - ${this.pathwayCohortData.pathway.name}`;
          fileDownload(data, `Pathway Roster - ${name}.xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
          console.error('failed to add students to class', error);
        });
    },
    async downloadSectionRosterSheet() {
      this.isBtnBusy = true;

      this.$store
        .dispatch('rosterStoreModule/fetchPathwayCohortSectionRoster', {
          id: this.pathwayCohortData.id,
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          const name = `${this.resolvePathwayCohortName(this.pathwayCohortData)} - ${this.pathwayCohortData.pathway.name}`;
          fileDownload(data, `${name} Cohort Roster with Sections.xlsx`);
        })
        .catch((error) => {
          console.log(error);
          this.isBtnBusy = false;
        });
    },
    async downloadAttendanceSheet(mode) {
      this.isBtnBusy = true;

      this.$store
        .dispatch('rosterStoreModule/exportSessionAttendance', {
          id: this.pathwayCohortData.id,
          queryParams: { mode: mode || 'standard' },
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          const name = `${this.resolvePathwayCohortName(this.pathwayCohortData)} - ${this.pathwayCohortData.pathway.name}`;
          fileDownload(data, `${name} All Student Attendance (${title(mode)}).xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
        });
    },
    async exportPlacementToMonday(doIntegrity) {
      this.isBtnBusy = true;

      this.$store
        .dispatch('rosterStoreModule/exportSessionToMonday', {
          sessionId: this.pathwayCohortData.id,
          integrity: doIntegrity,
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          console.log(data);

          this.$toast({
            component: ToastificationContent,

            props: {
              title: 'Exported items to monday',
              icon: 'ArchiveIcon',
              variant: 'success',
              text: `A total of ${data.length} profiles were eligible and published`,
            },
          });
        })
        .catch((error) => {
          this.isBtnBusy = false;
          console.error('failed to export session to monday', error);
        });
    },

    async exportPlacementToReceivable() {
      this.isBtnBusy = true;

      this.$store
        .dispatch('receivableStoreModule/importSession', {
          sessionId: this.pathwayCohortData.id,
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          console.log(data);

          this.$toast({
            component: ToastificationContent,

            props: {
              title: 'Created accounts receivable cohort',
              icon: 'ArchiveIcon',
              variant: 'success',
              text: 'The accounting receivable cohort + relevant accounts have been created',
            },
          });

          this.$emit('refresh', {});
        })
        .catch((error) => {
          this.isBtnBusy = false;
          console.error('failed to export session to monday', error);
        });
    },
  },
  setup() {
    const {
      resolvePathwayCohortVisibilityVariant,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortStatusIcon,
      resolvePathwayCohortVisibilityIcon,
      resolvePathwayCohortName,
    } = usePathwayCohorts();
    return {
      avatarText,
      resolvePathwayCohortName,
      resolvePathwayCohortVisibilityVariant,
      resolvePathwayCohortStatusVariant,
      resolvePathwayCohortStatusIcon,
      resolvePathwayCohortVisibilityIcon,
      title,
      skin,
      moment,
    };
  },
};
</script>

<style></style>
